/* ----------------------------------------------------------------------------
 * Account Page
 * ------------------------------------------------------------------------- */

.account,
.account__details,
.account__btn-actions-1,
.account__btn-actions-2,
.account__btn-signout {
	display: flex;
}

.account {
	justify-content: center;
	max-width: 100vw;
	min-height: min(93vh, calc(100vh - 55px));
	padding: 46px clamp(5px, 5%, 56px);
	margin-bottom: max(7vh, 55px);
	background-color: var(--primary-bg-color);
	font-family: var(--primary-font);
}

/* ----------------------------------------------------------------------------
 * Account - Grid Layout
 * ------------------------------------------------------------------------- */

.account__grid {
	display: grid;
	grid-template-rows: repeat(5, auto);
	justify-items: center;
	box-shadow: 0 2px 4px rgba(21, 17, 57, 0.352);
	border-radius: 8px;
	padding: 20px 15%;
	background-color: var(--secondary-bg-color);
	text-align: center;
	gap: 20px;
}

/* ----------------------------------------------------------------------------
 * Account Details Section
 * ------------------------------------------------------------------------- */

.account__details-section {
	grid-row: 1 / 2;
	flex-direction: column;
	gap: 40px;
	height: 100%;
}

/* ----------------------------------------------------------------------------
* Button Groups
* ------------------------------------------------------------------------- */

.account__btn-actions-1 {
	grid-row: 3;
	align-items: center;
}

.account__btn-actions-2 {
	grid-row: 5;
	align-items: flex-end;
}

/* ----------------------------------------------------------------------------
 * Button Styles
 * ------------------------------------------------------------------------- */

.account__btn-signout,
#account__btn-delete {
	font-size: clamp(1rem, 1.25vw, 1.15rem);
}

.account__btn-signout {
	display: flex;
	justify-content: center;
	width: fit-content;
	height: fit-content;
	padding: 12px 40px;
}

/* ----------------------------------------------------------------------------
 * Responsive Breakpoints
 * ------------------------------------------------------------------------- */
@media (min-width: 400px) {
	.account {
		padding: 56px clamp(5px, 5%, 56px);
	}

	.account__grid {
		padding: 30px 15%;
	}
}

@media (min-width: 1024px) {
	.account__grid {
		padding: 30px 20%;
	}
}

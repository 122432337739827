.note-insights-network {
	display: flex;
	flex-direction: column;
	height: min(93vh, calc(100vh - 55px));
	max-width: 100vw;
	font-family: var(--primary-font);
	color: var(--primary-text-color);
	overflow: hidden;
}

.note-insights-network__svg-layout {
	display: grid;
	grid: 30px 1fr 1fr 30px / 30px 1fr 1fr 30px;
	height: 100%;
	width: 100%;
	background-image: radial-gradient(
		circle,
		#fbfbfb,
		#f9f9f9,
		#f6f6f7,
		#f4f4f4,
		#f2f1f2,
		#edeced,
		#e9e6e9,
		#e4e1e4,
		#dbd8dc,
		#d2cfd5,
		#c9c6ce,
		#bfbdc7
	);
}

.note-insights-network__svg-layout--detailed-view {
	display: grid;
	grid: 10px 1fr 1fr 0px / 0px 1fr 1fr 0px;
	height: 50%;
	width: 100%;
	background-image: radial-gradient(
		circle,
		#fbfbfb,
		#f9f9f9,
		#f6f6f7,
		#f4f4f4,
		#f2f1f2,
		#edeced,
		#e9e6e9,
		#e4e1e4,
		#dbd8dc,
		#d2cfd5,
		#c9c6ce,
		#bfbdc7
	);
}

.note-insights-network__svg-container {
	grid-area: 2/2/4/4;
}

@media screen and (min-width: 708px) {
	.note-insights-network__svg-layout {
		grid: 40px 1fr 1fr 40px / 50px 1fr 1fr 50px;
	}
	.note-insights-network__svg-layout--detailed-view {
		grid: 8% 1fr 1fr 8% / 6% 1fr 1fr 6%;
	}
}

@media screen and (min-width: 1025px) {
	.note-insights-network {
		flex-direction: row;
	}

	.note-insights-network__svg-layout--detailed-view {
		grid: 40px 1fr 1fr 40px / 50px 1fr 1fr 50px;
		width: 55%;
		height: 100%;
	}
}

@font-face {
	font-family: 'Cardo';
	src: url('./fonts/Cardo/Cardo-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Cardo';
	src: url('./fonts/Cardo/Cardo-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Cardo';
	src: url('./fonts/Cardo/Cardo-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Lora';
	src: url('./fonts/Lora/Lora-Italic-VariableFont_wght.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Lora';
	src: url('./fonts/Lora/Lora-VariableFont_wght.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

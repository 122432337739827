/* ----------------------------------------------------------------------------
 * Notes Collection - Container
 * ------------------------------------------------------------------------- */

.notes-collection {
	display: flex;
	margin-bottom: max(7vh, 55px);
	max-width: 100vw;
	min-height: min(93vh, calc(100vh - 55px));
	padding: 30px 20px;
	background: var(--primary-bg-color);
}

/* ----------------------------------------------------------------------------
 * Notes Collection - Notecards
 * ------------------------------------------------------------------------- */

.notes-collection__notecards {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: flex-start;
	padding: 16px 0;
	gap: 30px;
	width: 100%;
}

/* ----------------------------------------------------------------------------
 * Responsive Breakpoints
 * ------------------------------------------------------------------------- */

@media screen and (min-width: 400px) {
	.notes-collection {
		padding: 40px;
	}

	.notes-collection__notecards {
		gap: 40px;
	}
}

/* ----------------------------------------------------------------------------
 * Welcome Page 
 * ------------------------------------------------------------------------- */

.welcome {
	height: 100vh;
	padding: 40px 30px;
	font-family: var(--primary-font);
	color: var(--primary-text-color);
	background-color: #fcfdfe;
}

.welcome,
.welcome__heading,
.welcome__logo-container {
	flex-direction: column;
}

.welcome,
.welcome__heading,
.welcome__title,
.welcome__logo-container,
.welcome__logo-img,
.welcome__logo-txt {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

/* ----------------------------------------------------------------------------
 * Welcome - Heading 
 * ------------------------------------------------------------------------- */

.welcome__heading {
	height: 85%;
}

/* ----------------------------------------------------------------------------
 * Welcome - Heading Title
 * ------------------------------------------------------------------------- */

.welcome__title {
	overflow: hidden;
	height: 15%;
	font-size: clamp(45px, 3.5vw, 70px);
	font-weight: 500;
	letter-spacing: 3.5px;
}

/* ----------------------------------------------------------------------------
 * Welcome - Logo 
 * ------------------------------------------------------------------------- */

.welcome__logo-container {
	overflow: hidden;
	height: 85%;
}

.welcome__logo-img {
	width: auto;
	height: 100%;
}

.welcome__logo-txt {
	transform: translate(0%, -100%);
	font-size: 1.25rem;
	font-weight: 200;
	text-align: center;
	letter-spacing: 2px;
	color: var(--link-icon-color);
}

/* ----------------------------------------------------------------------------
 * Welcome - Sign In Button
 * ------------------------------------------------------------------------- */

.welcome__btn-container {
	position: static;
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	align-items: center;
	width: 100%;
	height: 15%;
}

.welcome__btn {
	grid-column: 2;
	height: 50px;
}

.welcome__btn:before {
	content: '➔';
	margin-right: 5px;
}

/* ----------------------------------------------------------------------------
 * Responsive Breakpoints
 * ------------------------------------------------------------------------- */

@media only screen and (min-width: 708px) {
	.welcome {
		padding: 40px;
	}

	.welcome__heading {
		height: 100%;
	}

	.welcome__btn-container {
		position: absolute;
		top: max(40px, 5%);
		grid-template-columns: 1fr auto minmax(40px, 5%);
		height: auto;
	}
}

@media only screen and (min-width: 1291px) {
	.welcome {
		background-image: radial-gradient(
			circle,
			#ffffff,
			#ffffff,
			#ffffff,
			#ffffff,
			#ffffff,
			#ffffff,
			#ffffff,
			#f6f6f7,
			#f2f2f4,
			#efeff1,
			#ebebed,
			#e8e7ea
		);
	}
}

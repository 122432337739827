/* ----------------------------------------------------------------------------
 * Note Workspace 
 * ------------------------------------------------------------------------- */

.workspace {
	display: flex;
	justify-content: center;
	max-width: 100vw;
	height: 93vh;
	max-height: calc(100vh - 55px);
	padding: 30px 20px;
	background-image: radial-gradient(
		circle,
		#ffffff,
		#fefefe,
		#fefefe,
		#fdfdfd,
		#fcfcfc,
		#fafafa,
		#f8f8f9,
		#f6f6f7,
		#f2f2f4,
		#efeff1,
		#ebebed,
		#e8e7ea
	);
}

/* ----------------------------------------------------------------------------
 * Note Editor
 * ------------------------------------------------------------------------- */

.workspace__active-note {
	display: flex;
	width: clamp(40ch, 65%, 80ch);
	height: 100%;
}

/* ----------------------------------------------------------------------------
 * Responsive Breakpoints
 * ------------------------------------------------------------------------- */

@media screen and (min-width: 708px) {
	.workspace {
		padding: 40px;
	}
}

/* ----------------------------------------------------------------------------
 * Navigation Bar
 * ------------------------------------------------------------------------- */

.navbar * {
	background-color: var(--tertiary-bg-color);
}

.navbar {
	position: fixed;
	bottom: 0;
	left: 0;
	border: 1px solid var(--border-color);
	width: 100%;
	height: 7%;
	min-height: 55px;
}

.navbar__container {
	display: grid;
	grid-template-columns: auto 1fr calc(3.5rem);
	align-items: center;
	width: 100%;
	height: 100%;
}

.navbar__icon {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5rem;
	padding: 1rem;
}

.navbar__home-text {
	font: 300 1.7rem var(--primary-font);
	text-align: center;
	letter-spacing: 3.5px;
}

/* ----------------------------------------------------------------------------
 * Navigation Menu
 * ------------------------------------------------------------------------- */

.navbar__menu {
	position: fixed;
	top: 0;
	visibility: hidden;
	list-style: none;
	transform: translateX(-100%);
	overflow: hidden;
	transition: all 0.3s ease-out;
	height: 93%;
	max-height: calc(100% - 55px);
}

.navbar__menu--visible {
	display: flex;
	justify-content: center;
	visibility: visible;
	transform: translateX(0);
	box-shadow: 15px 0 20px -10px #ccc9cd;
	border: 1px solid var(--border-color);
	width: 85%;
	height: 93%;
	max-height: calc(100% - 55px);
}

/* ----------------------------------------------------------------------------
 * Navigation Links
 * ------------------------------------------------------------------------- */

.navbar__links {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin: 46px 20px;
	width: 100%;
	font: 1.4rem var(--secondary-font);
	text-align: center;
}

.navbar__links li {
	margin: 3%;
	padding: 3%;
}

/* ----------------------------------------------------------------------------
 * Responsive Breakpoints
 * ------------------------------------------------------------------------- */

@media screen and (min-width: 400px) {
	.navbar__container {
		grid-template-columns: auto 1fr calc(3.5rem + 20px);
	}

	.navbar__icon {
		margin-left: 20px;
	}

	.navbar__links {
		margin: 56px 20px;
	}
}

@media only screen and (min-width: 576px) {
	.navbar__menu--visible {
		width: 17.5%;
		min-width: 260px;
	}

	.navbar__links li {
		margin: 4%;
		padding: 4%;
	}
}

/* ----------------------------------------------------------------------------
 * Account Details - Container
 * ------------------------------------------------------------------------- */

.account-details {
	display: flex;
	flex-direction: column;
	gap: 35px;
	color: var(--primary-text-color);
}

/* ----------------------------------------------------------------------------
 * Account Details - Header
 * ------------------------------------------------------------------------- */

.account-details__header {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 40px;
}

.account-details__title {
	display: flex;
	align-items: center;
	font-size: clamp(2.15rem, 3.4vw, 3.68rem);
	font-weight: 600;
}

.account-details__username {
	font-size: clamp(1.25rem, 2.5vw, 3rem);
	font-weight: 500;
	text-shadow: var(--primary-text-shadow);
}

/* ----------------------------------------------------------------------------
 * Account Details - Personal Information 
 * ------------------------------------------------------------------------- */

.account-details__personal-info {
	display: flex;
	flex-direction: column;
	gap: 25px;
}

.account-details__subtitle {
	font-size: clamp(1.1rem, 1.75vw, 1.5rem);
	font-weight: 600;
}

/* ----------------------------------------------------------------------------
 * Account Details - Items
 * ------------------------------------------------------------------------- */

.account-details__info-item {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	height: 50%;
	gap: 20px;
	font-size: clamp(1rem, 1.25vw, 1.15rem);
}
